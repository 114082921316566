.toggle-buttons-wrapper {
  padding: 20px 0;
  width: 100%;
}

.toggle-button {
  display: flex !important;
  justify-content: space-between !important;
  padding: 25px 15px !important;
}

.toggle-button.Mui-selected {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.toggle-button span {
  pointer-events: none;
  line-height: initial;
}

.toggle-button.Mui-selected span {
  color: #fff !important;
}

.toggle-button.Mui-selected svg {
  fill: #fff;
}