.floor-mat-images-container {
  margin-top: 25px;
  padding-right: 50px;
}

@media screen and (max-width: 899px) {
  .floor-mat-images-container {
    padding-right: 0;
  }
}

.floor-mat-image-container {
  position: relative;
}

.floor-mat-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.floor-mat-base-image {
  position: relative !important;
  display: block;
  visibility: hidden;
}

.floor-mat-image-info {
  font-weight: 700;
  font-size: 10px;
  color: #000;
}

.carbon-fiber-mat-trunk-base-image {
  padding: 3.75% 3.5% 4% 2.5%;
}
