@media screen and (max-width: 899px) {
  .car-cover-images-container {
    margin-top: 15px;
  }
}

@media screen and (min-width: 900px) {
  .car-cover-images-container {
    padding-right: 50px;
  }
}

.car-cover-image-container img {
  width: 100%;
  height: auto;
}

.car-cover-images-info {
  font-weight: 700;
  font-size: 10px;
  color: #000;
}