.sheepskin-floor-mats-color-swatch-container svg {
  display: none !important;
}

.sheepskin-floor-mats-color-swatch-container .color-swatch-container {
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px 10px;
}

.sheepskin-floor-mats-color-swatch-container .color-swatch-img-container {
  height: 60px;
  width: 60px;
}

.sheepskin-floor-mats-color-swatch-container .color-swatch-name {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
}

.sheepskin-floor-mats-color-swatch-container .color-swatch-container img {
  padding: 3px;
  border: 2px solid transparent;
}
.sheepskin-floor-mats-color-swatch-container .color-swatch-container.is-selected img {
  border: 2px solid red;
}

.sheepskin-floor-mats-price {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
}
