.info-tabs-container {
  padding: 50px 50px 0 0;
}

@media screen and (max-width: 899px) {
  .info-tabs-container {
    padding-right: 0;
  }
}

.info-tabs-toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.info-tabs-toggle-group {
  width: 100%;
  padding: 0 25px;
}

.info-tabs-toggle-button {
  width: 33%;
}

.info-tabs-text-container {
  color: #333;
  display: none;
  padding: 0 50px;
}

.info-tabs-text-container.is-active {
  display: block;
}

@media screen and (max-width: 798px) {
  .info-tabs-toggle-group {
    padding: 0;
  }

  .info-tabs-text-container {
    padding: 0 25px;
  }
}
