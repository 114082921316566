.product-images-container {
  padding-right: 50px;
}

@media screen and (max-width: 899px) {
  .product-images-container {
    padding-right: 0;
  }
}

.product-image-container {
  display: none;
}

.product-image-container.is-selected {
  display: block;
}

.product-image-container img {
  width: 100%;
  height: auto;
}

.product-images-info {
  font-weight: 700;
  font-size: 10px;
  color: #000;
}