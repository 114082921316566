.floor-mat-product-option-container {
  width: 22%;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin-right: 10px;
  padding: 5px;
  border: 2px solid transparent;
  cursor: pointer;
}

.floor-mat-product-option {
  margin-bottom: 10px;
}

.floor-mat-product-option-container.is-selected {
  border: 2px solid red;
}

.floor-mat-product-option img {
  width: 100%;
  height: auto;
}

.floor-mat-product-option-note {
  margin-top: 10px;
}
