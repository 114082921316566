.cart-buttons-wrapper {
  margin-top: 25px;
}

.floor-mat-cart-buttons-wrapper {
  margin-top: 50px;
}

.cart-buttons-wrapper .quantity-selector {
  display: flex;
  flex-direction: row;
}

.cart-buttons-wrapper .quantity-input {
  width: 75px;
  border-radius: 0;
  text-align: center;
}

.cart-buttons-wrapper .quantity-input .MuiInputBase-root {
  border-radius: 0;
}

.cart-buttons-wrapper .quantity-input .MuiInputBase-input {
  text-align: center;
}

.cart-total-text {
  font-size: 22px;
}

.cart-buttons-wrapper .quantity-button {
  border-radius: 0;
}

.cart-buttons-wrapper .add-to-cart-button {
  height: 100%;
  border-radius: 0;
}
.cart-buttons-wrapper .add-to-cart-button.disabled {
  background-color: #ddd !important;
}
