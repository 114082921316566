.product-info-container {
  border-bottom: 1px solid #000;
  margin-right: 25px;
  margin-bottom: 15px;
}

.product-info-container .product-info-title {
  color: #333;
  margin-top: 0;
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.product-rating-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0;
}

.product-rating-container .product-rating-count {
  margin: 3px 0 0 15px !important;
}

.product-short-description {
  margin-bottom: 0;
}

@media screen and (max-width: 798px) {
  .product-info-container {
    margin-bottom: 25px;
    margin-right: 0;
  }
}
