.embroidery-selector-container {
  padding: 20px 20px 0 0;
}

.embroidery-title-container .embroidery-title,
.embroidery-title-container .embroidery-price {
  color: #333;
}

.embroidery-selector-container h3 {
  font-family: Oswald;
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

.embroidery-container .embroidery-swatches-title {
  color: #333;
}

.embroidery-subtitle-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.embroidery-subtitle {
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.embroidery-toggle-second-line-button {
  background: transparent;
  padding: 0;
  border: 0;
  margin: 0 0 10px 10px;
  min-height: unset;
}
.embroidery-toggle-second-line-button:hover {
  background: transparent;
  border: none;
}

.embroidery-description-list {
  margin-bottom: 30px;
}
.embroidery-description-list li {
  margin-bottom: 12px;
  font-size: 16px;
}

.embroidery-text-field {
  margin-bottom: 15px !important;
}
.embroidery-text-field :not(.Mui-error) .MuiOutlinedInput-notchedOutline {
  border-radius: 4px;
}
.embroidery-text-field :not(.Mui-error) .MuiOutlinedInput-notchedOutline {
  border-color: #000000de !important;
}
.embroidery-text-field input {
  color: #000000de;
  margin-bottom: 0;
  font-size: 16px;
  border-radius: 4px;
}

.embroidery-swatches .color-swatch-img-container {
  border: 2px solid lightgrey;
}
.embroidery-swatches .color-swatch-container.is-selected .color-swatch-img-container {
  border: 2px solid red;
}
.embroidery-swatches .color-swatch-container svg {
  display: none;
}
