.color-swatch-container {
  width: 65px;
  height: 65px;
  padding: 5px 10px 5px 0;
  cursor: pointer;
}

.color-swatch-img-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.color-swatch-container .color-swatch-name {
  display: block;
  text-align: center;
}

.color-swatch-container svg {
  display: none;
  position: absolute;
  width: 35px;
  height: 35px;
  fill: #fff;
}

.color-swatch-container.is-selected svg {
  display: inline-block;
}

.color-swatch-container img {
  width: 100%;
  height: 100%;
}