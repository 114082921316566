body {
  background: #fff;
}

.conspire-bhm-widget-container {
  padding: 50px 10px 0 10px;
  background: #fff;
}

.main-image-container {
  padding: 0 25px;
}

.main-image-container img {
  max-width: 100%;
}

.piping-cost {
  margin-top: 10px;
}

@media screen and (max-width: 899px) {
  .mobile-hide {
    display: none;
  }

  .main-image-container {
    padding: 0;
  }
}

@media screen and (min-width: 900px) {
  .desktop-hide {
    display: none;
  }
}
