.floor-mats-color-swatch-container svg {
  display: none !important;
}

.floor-mats-color-swatch-container .color-swatch-container {
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px 10px;
}

.floor-mats-color-swatch-container .color-swatch-container img {
  padding: 3px;
  border: 2px solid transparent;
}
.floor-mats-color-swatch-container .color-swatch-container.is-selected img {
  border: 2px solid red;
}

.floor-mats-info-tabs.info-tabs-container {
  padding: 50px 0 0;
}