.no-logo-selector-container {
  padding: 20px 20px 0 0;
}

.no-logo-title-container .no-logo-title,
.no-logo-title-container .no-logo-price {
  color: #333;
}

.no-logo-selector-container h3 {
  font-family: Oswald;
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}
