.flannel-lining-selector-container {
  padding: 20px 20px 0 0;
}

.flannel-lining-title-container .flannel-lining-title,
.flannel-lining-title-container .flannel-lining-price {
  color: #333;
}

.flannel-lining-selector-container h3 {
  font-family: Oswald;
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}
