.car-model-selector-container {
  padding: 20px 20px 0 0;
}

.car-model-selector-container .car-model-form-control {
  margin: 10px 0;
}

.selected-car-container {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-car-wrapper p {
  margin: 10px 0;
}

.selected-car-container .selected-car {
  width: 75%;
  padding-right: 20px;
  font-weight: 700;
}
.selected-car-container .clear-selection-button:hover {
  border: none !important;
}

p.selected-car-option {
  margin-top: 0;
  padding-left: 5px;
  font-weight: 700;
}

.car-options-selected-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.car-options-selected-container svg {
  fill: green;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.selected-car-container .selected-car,
.car-options-selected-notice,
.car-options-empty-notice,
.car-option-price {
  color: #333;
}

.car-option-price-container {
  display: flex;
  align-items: center;
}

.car-option-price {
  font-size: 25px;
}
.car-option-price.car-option-price-has-discount {
  font-size: 18px;
  text-decoration: line-through;
  margin-left: 12px;
}
.car-option-price.car-option-price-discount {
  color: red;
}

.lowest-price-container {
  margin-top: 15px;
}
.lowest-price-container.hide-after-widget-loads {
  text-align: right;
  margin-top: 0;
}
.lowest-price {
  font-size: 18px;
  margin-bottom: 0;
}
